








/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { Hive } from '@/types';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class HiveAdoptions extends Vue {
  @Prop(Object) readonly hive!: Hive

  mounted() {
    console.log('HiveAdoptions: mounted()'); // eslint-disable-line no-console
  }

  get hiveOwners(): string[] {
    return this.hive?.owners ?? [];
  }

  get adoptionsCount(): number {
    return this.hiveOwners.length;
  }

  get text(): string {
    if (this.adoptionsCount === 0) return 'Nessuna adozione attiva';
    if (this.adoptionsCount === 1) return 'Una adozione attiva';
    return `${this.adoptionsCount} adozioni attive`;
  }

  get textClass(): string {
    if (this.adoptionsCount === 0) return 'grey--text';
    return 'green--text font-weight-bold';
  }
}
